/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ReactHtmlParser from 'react-html-parser';

import { httpClient } from 'src/app/helpers';
import { Page, InputField, Button } from 'src/app/components';
import HeaderBanner from 'src/assets/images/Vulcain_LP2_Hero.png';
import iPhone from 'src/assets/images/Vulcain_LP2_Iphone.png';
import Screen from 'src/assets/images/Vulcain_LP2_Screen.png';
import VulcainLogo from 'src/assets/images/Vulcain_LP2_Vulcainlogo.png';
import SCVerifiedLogo from 'src/assets/images/Vulcain_LP2_SCVerifiedlogo.png';
import Thumbnail from 'src/assets/images/Vulcain_LP2_Thumbnail.png';

import Check from 'src/assets/images/check.png';

const VideoURL =
  'https://storageambafrontdev.blob.core.windows.net/dev/media/video_sharecare_x_vulcain.mp4';

const CONTACT_US_SCHEMA = Yup.object().shape({
  firstName: Yup.string().max(250, 'forms.too_long').required('required'),
  lastName: Yup.string().max(250, 'forms.too_long').required('required'),
  from: Yup.string().email('format').max(250, 'forms.too_long').required('required'),
  phoneNumber: Yup.string()
    .max(250, 'forms.too_long')
    .matches(
      /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[ ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,4})?$/,
      'format'
    ),
  companyName: Yup.string().max(250, 'forms.too_long').required('required'),
  jobTitle: Yup.string().max(250, 'forms.too_long'),
  message: Yup.string().max(250, 'forms.too_long').required('required'),
});

const INITIAL_CONTACT_VALUES = {
  firstName: '', // = first name
  lastName: '', // = last name
  from: '', // = email
  phoneNumber: '', // = phone number
  companyName: '', // = company name
  jobTitle: '', // = job title
  message: '', // = message
};

const HomeCommunication = () => {
  const { t } = useTranslation('vulcain');

  const [contactSuccess, setContactSuccess] = useState(null);

  const handleContact = async (values, { setSubmitting, resetForm }) => {
    if (window.gtag) window.gtag('event', 'Click_contact_us');
    setSubmitting(true);

    try {
      const { status } = await httpClient.post('/contact/landing?origin=vulcain', values);

      if (status === 200) {
        setSubmitting(false);
        setContactSuccess('SUCCESS');
        resetForm();
      } else {
        throw Error('Contact request failed.');
      }
    } catch (error) {
      setContactSuccess('ERROR');
      setSubmitting(false);
    }
  };

  return (
    <Page>
      <Page.Header className="pt-16 bg-secondary-green-light text-primary-dark">
        <div className="container">
          <div className="flex flex-col-reverse items-start justify-between pb-12 lg:flex-row">
            <div className="pt-12 lg:ml-28">
              <div className="max-w-2xl">
                <h1 className="text-5xl tiempos">{t('vulcain:lp2.header.tagline1')}</h1>
                <h1 className="text-5xl tiempos">{t('vulcain:lp2.header.tagline2')}</h1>
                <p className="flex flex-col gap-6 pt-6 text-primary-dark last:pb-0">
                  {t('vulcain:lp2.header.introductions', { returnObjects: true }).map((item) => {
                    const { type, content, sublist } = item;
                    switch (type) {
                      case 'list':
                        return (
                          <div>
                            {ReactHtmlParser(content)}
                            <ul className="list-disc pl-7">
                              {sublist.map(({ subcontent }) => (
                                <li>{ReactHtmlParser(subcontent)}</li>
                              ))}
                            </ul>
                          </div>
                        );
                      default:
                        return <div>{ReactHtmlParser(content)}</div>;
                    }
                  })}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-end max-w-xl">
              <img src={HeaderBanner} alt="Sharecare | HealthSecurity" />
            </div>
          </div>
        </div>
      </Page.Header>
      <Page.Main className="text-primary-grey">
        <section className="container pt-16 pb-16 md:pb-48">
          <div className="flex justify-center">
            <video src={VideoURL} className="md:max-w-screen-md" controls poster={Thumbnail}>
              Sorry, your browser doesn&apos;t support embedded videos.
            </video>
          </div>
        </section>
        <section className="flex flex-col items-center px-6 pt-16 pb-16 md:px-12 md:flex-row bg-secondary-green-light vulcain_sectionheight">
          <div className="flex flex-col items-center space-y-8 md:space-y-16">
            <div className="flex flex-row items-center px-2 py-1 bg-white border-2 border-gray-300 rounded-full md:px-8 md:py-6">
              <div className="flex justify-center w-16 md:w-32">
                <img src={SCVerifiedLogo} alt="Sharecare | HealthSecurity" className="h-full" />
              </div>
              <div className="text-xs md:text-base">with</div>
              <div className="flex justify-center w-16 md:w-32">
                <img src={VulcainLogo} alt="Sharecare | HealthSecurity" className="h-full" />
              </div>
            </div>
            <div className="space-y-4 lg:px-20">
              {t('vulcain:lp2.check_list', { returnObjects: true }).map((item) => (
                <div className="flex items-center space-x-4">
                  <img src={Check} alt="Sharecare | HealthSecurity" className="h-full" />
                  <span>{ReactHtmlParser(item)}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex pt-8 md:pt-0">
            <img src={iPhone} alt="Sharecare | HealthSecurity" />
          </div>
        </section>
        <section className="container pb-20 pt-14 md:pt-36">
          <div className="m-auto text-center">
            <span className="text-4xl text-primary-dark tiempos md:text-5xl">
              {t('vulcain:lp2.data_tagline')}
            </span>
          </div>
          <div className="flex justify-center space-y-12 md:pt-8 md:items-center md:flex-row md:space-x-12 md:space-y-0 ">
            <img
              className="hidden w-1/4 md:block md:h-full md:w-48"
              src={iPhone}
              alt="Sharecare | HealthSecurity"
            />
            <img className="md:w-1/2" src={Screen} alt="Sharecare | HealthSecurity" />
          </div>
        </section>
        <section id="contact-us" className="overflow-hidden">
          <div className="h-20 bg-secondary-green-light" />
          <div className="bubble-gs">
            <Formik
              initialValues={INITIAL_CONTACT_VALUES}
              validationSchema={CONTACT_US_SCHEMA}
              onSubmit={handleContact}
            >
              {({ isValid, isSubmitting, dirty }) => (
                <Form className="container relative w-screen max-w-screen-lg pt-24 space-y-6 md:pt-48">
                  <div className="flex flex-col items-center justify-center text-center">
                    <span className="text-4xl lg:text-5xl text-primary-dark tiempos">
                      {t('lp2.contact.tagline')}
                    </span>
                  </div>
                  <div className="grid grid-cols-1 gap-6 pt-4 md:grid-cols-2">
                    <InputField
                      name="firstName"
                      label="lp2.contact.firstName"
                      autoComplete="given-name"
                      namespaces={['vulcain']}
                    />
                    <InputField
                      name="lastName"
                      label="lp2.contact.lastName"
                      autoComplete="family-name"
                      namespaces={['vulcain']}
                    />
                    <InputField
                      name="from"
                      label="lp2.contact.email"
                      autoComplete="email"
                      namespaces={['vulcain']}
                    />
                    <InputField
                      name="phoneNumber"
                      label="lp2.contact.phone"
                      autoComplete="phone"
                      namespaces={['vulcain']}
                    />
                    <InputField
                      name="companyName"
                      label="lp2.contact.company"
                      autoComplete="company"
                      namespaces={['vulcain']}
                    />
                    <InputField name="jobTitle" label="lp2.contact.role" namespaces={['vulcain']} />
                    <div className="col-span-1 md:col-span-2">
                      <InputField
                        type="textarea"
                        name="message"
                        label="lp2.contact.message"
                        namespaces={['vulcain']}
                      />
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="w-full max-w-sm">
                      <Button
                        type="submit"
                        disabled={!(isValid && dirty) || isSubmitting}
                        loading={isSubmitting}
                      >
                        Envoyer
                      </Button>
                    </div>
                  </div>
                  <div className="pt-12 lg:ml-28">
                  Sharecare est responsable du traitement des données collectées dans ce formulaire. Elles ne seront utilisées que pour vous recontacter pour répondre à vos questions.<br></br>
                  <a href="/rgpd" target="_blank" class="form__mention-link">En savoir plus...</a>
                    </div>
                  {contactSuccess && (
                    <div>
                      {contactSuccess === 'SUCCESS' && (
                        <div className="px-6 py-4 text-gray-800 rounded-md bg-primary-light">
                          Votre message a bien été envoyé! Une personne de l&apos;équipe vous
                          contactera dans les 2 jours.
                        </div>
                      )}
                      {contactSuccess === 'ERROR' && (
                        <div className="px-6 py-4 text-white bg-red-500 rounded-md">
                          Oups, merci de ré-essayer plus tard.
                        </div>
                      )}
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </section>
      </Page.Main>
    </Page>
  );
};

export default HomeCommunication;
